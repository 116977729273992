import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import "./Header.scss";

export default function Header({ open, isDetail, percent, nearTop }) {
  const prevPercent = useRef(percent);
  const active = useRef(true);
  const [goingUp, setGoingUp] = useState(false);

  useEffect(() => {
    setGoingUp(prevPercent.current > percent);

    if (percent < 10) {
      active.current = true;
    } else {
      if (prevPercent.current < percent) {
        active.current = false;
      } else {
        active.current = true;
      }
    }
    prevPercent.current = percent;
  }, [percent]);

  return (
    <header
      className={`header fixed ${active.current === true ? "active" : ""} ${
        isDetail ? "has-bg" : ""
      } ${!nearTop && goingUp ? "pastThreshold" : ""}`}
    >
      <div className="header_main">
        <div className="logo">
          <Link className="logo-link" to={"/"}>
            <img
              className="desktop-logo"
              src={require("./../assets/svg/logo.svg")}
              alt="Colormatics Logo"
            />
            <img
              className="mobile-logo"
              src={require("./../assets/svg/icons/home-color.svg")}
              alt="Colormatics Logo"
            />
          </Link>
          <div
            className="menu-icon"
            onClick={() => {
              if (open) {
                open();
              }
            }}
          ></div>
        </div>
      </div>
      <div className="progress" style={{ width: Math.round(percent) + "%" }}>
        <div className="bar"></div>
      </div>
    </header>
  );
}
