/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header";
import Footer from "./Footer";

import NavigationMenu from "./NavigationMenu";
import ContactModal from "./ContactModal";
import { CSSTransition } from "react-transition-group";

import "./../style/app.scss";
import "slick-carousel/slick/slick.css";
import "./layout.scss";

const Layout = ({ children, location, isDetail }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      allPrismicNavigation {
        nodes {
          data {
            nav {
              ... on PrismicNavigationNavNavItem {
                id
                primary {
                  label {
                    text
                  }
                  link {
                    uid
                  }
                }
                items {
                  sub_nav_link_label {
                    text
                  }
                  sub_nav_link {
                    uid
                  }
                }
              }
            }
            display_name {
              text
            }
          }
        }
      }
      allPrismicGlobal {
        nodes {
          data {
            facebook {
              url
            }
            footer_cta_title {
              text
            }
            locations {
              title {
                text
              }
              phone
              email
              address {
                html
              }
            }
            twitter {
              url
            }
            instagram {
              url
            }
          }
        }
      }
    }
  `);

  const navData = data.allPrismicNavigation.nodes[0].data;
  const globalData = data.allPrismicGlobal.nodes[0].data;

  const [showMenu, setShowMenu] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [percent, setPercent] = useState(0);
  const [nearTop, setNearTop] = useState(true);

  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  const calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const docHeight = getDocHeight();
    const pastThreshold = Math.abs(scrollTop) > windowHeight * 0.3;
    setNearTop(!pastThreshold);

    const totalDocScrollLength = docHeight - windowHeight;
    setPercent(Math.floor((scrollTop / totalDocScrollLength) * 100));
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(calculateScrollDistance);
    });
  }, []);

  const navigationOpen = () => {
    setShowMenu(true);
  };

  const navigationClose = () => {
    setShowMenu(false);
  };

  const contactOpen = () => {
    setShowContact(true);
  };

  const contactClose = () => {
    setShowContact(false);
  };

  return (
    <main>
      <div className="App">
        <Header
          isDetail={isDetail}
          percent={percent}
          nearTop={nearTop}
          open={navigationOpen}
        />
        <div className="site-wrapper">{children}</div>
      </div>
      <Footer nav={navData.nav} global={globalData} open={contactOpen} />
      <CSSTransition
        in={showMenu}
        timeout={{ enter: 800, exit: 300 }}
        unmountOnExit
        classNames="animated-menu"
      >
        <NavigationMenu
          nav={navData.nav}
          global={globalData}
          location={location}
          close={navigationClose}
        />
      </CSSTransition>
      <CSSTransition
        in={showContact}
        timeout={{ enter: 300, exit: 300 }}
        unmountOnExit
        classNames="modal-animation"
      >
        <ContactModal close={contactClose} />
      </CSSTransition>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
