import React from 'react'
import './LocationItem.scss'

const LocationItem = ({ styled, location }) => {
  const { address, email, phone, title, thumb, link } = location
  return (
    <div className={`location-item ${styled ? 'styled' : ''}`}>
      {thumb && styled && (
        <div className="location-item--image">
          <img src={thumb.fluid.src} alt={thumb.fluid.alt} />
        </div>
      )}
      <div className="location-item--inner">
        {title && <h5>{title.text}</h5>}
        {address && (
          <div dangerouslySetInnerHTML={{ __html: address.html }}></div>
        )}
        <ul>
          <li>
            P:{' '}
            <a
              href={`tel:${phone}`}
              onClick={() => {
                if (window.ga) {
                  window.ga('send', 'event', 'Phone', 'Click', 'Phone Call')
                }
              }}
            >
              {phone}
            </a>
          </li>
          <li>
            E:{' '}
            <a
              href={`mailto:${email}`}
              onClick={() => {
                if (window.ga) {
                  window.ga('send', 'event', 'Email', 'Click', 'Email Click')
                }
              }}
            >
              {email}
            </a>
          </li>
        </ul>
        {link && link.uid && (
          <div style={{ marginTop: '30px' }}>
            <a
              style={{
                margin: 0,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 600,
              }}
              className="button button-round button-small button-pink"
              href={`/location/${link.uid}`}
              target={link.target}
            >
              Learn More
            </a>
          </div>
        )}
      </div>
    </div>
  )
}
export default LocationItem
