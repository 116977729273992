import React from "react";
import "./ContactModal.scss";

import * as typeformEmbed from "@typeform/embed";

const SLIDE_MAX = 7;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.el = null;
  }

  componentDidMount() {
    if (this.el) {
      typeformEmbed.makeWidget(this.el, "https://form.typeform.com/to/EaOM8Q", {
        hideFooter: true,
        hideHeaders: true,
      });
    }
  }

  render() {
    return (
      <div className="contact-modal">
        <div className="contact-modal__inner">
          <div
            className="contact-modal__close"
            onClick={() => {
              if (this.props.close) {
                this.props.close();
              }
            }}
          ></div>
          <div>
            <div
              ref={(el) => (this.el = el)}
              style={{ width: "100%", height: "550px" }}
            />
          </div>
        </div>
      </div>
    );
  }
}
